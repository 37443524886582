<template>
    <div>
        <zw-select-group
            v-model="inputVal.status"
            :options="customerStatuses"
            name="status"
            validate="required"
            disable-label
        ></zw-select-group>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'ChangeStatusOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatuses']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        customerStatuses() {
            const customerStatuses = this.getEntityStatuses()['customer'] || []
            let list = {}
            customerStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        }
    },
}
</script>